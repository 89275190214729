import {Component, Input} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-phone',
  templateUrl: './mm-phone.component.html',
  styleUrls: ['./mm-phone.component.scss'],
})
export class MmPhoneComponent {

  @Input() phoneNumber: string = '';

  constructor(private modalController: ModalController) {
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
