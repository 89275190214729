import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmRestaurantPauseComponent } from './mm-restaurant-pause.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MmRestaurantPauseComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    MmRestaurantPauseComponent
  ]
})
export class MmRestaurantPauseModule {
}
