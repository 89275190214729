import {Injectable} from '@angular/core';
import {ToastController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toast: ToastController) {
  }

  public async presentToast(msg: string) {
    const toast = await this.toast.create({
      message: msg,
      duration: 5000,
      position: 'top',
      animated: true,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'X',
    });
    toast.present();
  }
}
