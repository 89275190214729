import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MmCityZipSearchComponent} from "./mm-city-zip-search.component";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    MmCityZipSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    MmCityZipSearchComponent
  ]
})
export class MmCityZipSearchModule {
}
