import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslatePipe} from '../translate.pipe';

import {ShortTimePipe} from '../short-time.pipe';
import {CardComponent} from '../card/card.component';
import {DynamicHtmlComponent} from '../dynamic-html/dynamic-html.component';
import {NumHufPipe} from '../num-huf.pipe';
import {HeadbarComponent} from '../home/headbar/headbar.component';
import {FormsModule} from '@angular/forms';
import {LoadingComponent} from '../loading/loading.component';
import {DishListComponent} from '../dish-list/dish-list.component';
import {MmCitySearchModule} from '../mm-city-search/mm-city-search.module';
import {MmCityZipSearchModule} from '../mm-city-zip-search/mm-city-zip-search.module';
import {MmLocationsearchModule} from '../mm-locationsearch/mm-locationsearch.module';
import {MmPhoneVoteModule} from '../mm-phone-vote/mm-phone-vote.module';
import {MmPhoneModule} from '../mm-phone/mm-phone.module';
import {MmRestaurantBusyModule} from '../mm-restaurant-busy/mm-restaurant-busy.module';
import {MmRestaurantPauseModule} from '../mm-restaurant-pause/mm-restaurant-pause.module';

@NgModule({
  declarations: [
    TranslatePipe,
    ShortTimePipe,
    CardComponent,
    DynamicHtmlComponent,
    LoadingComponent,
    NumHufPipe,
    HeadbarComponent,
    DishListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MmCitySearchModule,
    MmCityZipSearchModule,
    MmLocationsearchModule,
    MmPhoneVoteModule,
    MmRestaurantBusyModule,
    MmRestaurantPauseModule,
    MmPhoneModule
  ],
  providers: [TranslatePipe],
  exports: [
    TranslatePipe,
    ShortTimePipe,
    CardComponent,
    DynamicHtmlComponent,
    NumHufPipe,
    HeadbarComponent,
    LoadingComponent,
    DishListComponent,
  ]
})
export class PipeModule {
}
