import {environment as envs} from '../environments/environment';
import {Component, HostListener} from '@angular/core';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import * as moment from 'moment';
import {RestApiService} from './rest-api.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {GoogleMaps, Environment} from '@ionic-native/google-maps/ngx';
import {TranslatePipe} from './translate.pipe';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {HeaderService} from './home/headbar/header.service';
import {LocationManagerService} from './location-manager.service';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {LoginPage} from './login/login.page';
import {RestaurantPage} from './home/restaurant/restaurant.page';
import {ToastService} from './toast.service';
import {MmCitySearchComponent} from './mm-city-search/mm-city-search.component';

declare let gtag: Function;
declare let fbq: Function;
declare let jQuery: any;
declare let gaEvent: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  providers: []
})
export class AppComponent {
  navHistory: any[] = [];
  backedCouse: boolean = false;

  lastTimeBackPress = 0;
  timePeriodToExit = 3000;
  guideImages: any[] = [];
  guideVersion: string = '';
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    zoom: false,
  };

  imageHost = envs.imageUrl;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private api: RestApiService,
    private router: Router,
    private geolocation: Geolocation,
    private headerServ: HeaderService,
    private locMan: LocationManagerService,
    private nav: NavController,
    private lang: TranslatePipe,
    public alertController: AlertController,
    private arouter: ActivatedRoute,
    private deeplinks: Deeplinks,
    private toastService: ToastService,
    private modalController: ModalController,
  ) {

    this.router.events.subscribe(r => {
      if (r instanceof NavigationStart) {
        if (r.navigationTrigger === 'popstate') {
          this.backedCouse = true;
          this.navHistory.pop();
        }
      }
      if (r instanceof NavigationEnd) {
        if (!this.backedCouse) {
          this.navHistory.push(r);
          if (this.navHistory.length > 1) {
            this.api.canGoBack = true;
          }
        }
        this.backedCouse = false;

        gtag('event', 'page_view', {
          page_path: r.urlAfterRedirects,
        });
      }

    });

    this.initializeApp();

    moment.locale('hu', {
      weekdaysShort: 'vas|hét|kedd|szer|csüt|pén|szo'.split('|'),
      week: {
        dow: 0,
        doy: 0
      }
    });

    this.api.guideSubject.subscribe(r => {
      if (r.success) {
        this.guideVersion = r.data.PageDescription;
        this.guideImages = r.data.PagePhotos;
      }
    });

    // Ha nem választott még várost, vagy lejárt a választás
    if (!localStorage.getItem('cityClicked') ||
        JSON.parse(localStorage.getItem('cityClicked')).expiry < new Date().getTime()) {
      this.showCitySelectModal();
    } else {
      this.showPopup();
    }

    // localStorage beállítás random generált id-vel 1 napra
    if (!localStorage.getItem('uid')) {
      const uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

      const item = {
        value: uid,
        expires: moment().add(1, 'days').toDate()
      };

      localStorage.setItem('uid', JSON.stringify(item));
    }


    // pausedRestaurantUserResponse lejárat figyelése(5 perc)
    if (localStorage.getItem('pausedRestaurantUserResponse')) {
      const pausedRestaurantUserResponse = JSON.parse(localStorage.getItem('pausedRestaurantUserResponse'));
      if (pausedRestaurantUserResponse[0].date < new Date().getTime() - 1000 * 60 * 5) {
        localStorage.removeItem('pausedRestaurantUserResponse');
      }
    }

    // busyRestaurantUserResponse lejárat figyelése(5 perc)
    if (localStorage.getItem('busyRestaurantUserResponse')) {
      const pausedRestaurantUserResponse = JSON.parse(localStorage.getItem('busyRestaurantUserResponse'));
      if (pausedRestaurantUserResponse[0].date < new Date().getTime() - 1000 * 60 * 5) {
        localStorage.removeItem('busyRestaurantUserResponse');
      }
    }

    this.initGA();
    this.initFB();
  }

  private showPopup() {
    // Ha még nem volt az oldalon akkor a sima popupot jelenítjük meg egyébként a visszatérő vásárló popupot
    this.api.getPopups(JSON.parse(localStorage.getItem('cityClicked')).value, localStorage.getItem('popups-seen') !== null)
        .subscribe(r => {
        if (localStorage.getItem('popups-seen') === null) {
          this.api.guideSubject.next(r);
          localStorage.setItem('popups-seen', JSON.stringify([{'link': r.data.PageLink, 'date': new Date().getTime()}]));
        } else {
            const popupsSeenStorage = JSON.parse(localStorage.getItem('popups-seen'));
            // Tömbben keresünk link alapján
            const seen = popupsSeenStorage.find((element) => {
                return element.link === r.data.PageLink;
            });

            // Ha már látta viszont 7 napnál korábban akkor megjelenítjük
            if (seen && new Date().getTime() - seen.date > 1000 * 60 * 60 * 24 * 7) {
                this.api.guideSubject.next(r);
                // Ha már látta viszont 7 napon túl akkor töröljük a tömbből és hozzáadjuk az újat
                popupsSeenStorage.splice(popupsSeenStorage.indexOf(seen), 1);

                popupsSeenStorage.push({'link': r.data.PageLink, 'date': new Date().getTime()});
                localStorage.setItem('popups-seen', JSON.stringify(popupsSeenStorage));
            } else if (!seen) {
                this.api.guideSubject.next(r);
                popupsSeenStorage.push({'link': r.data.PageLink, 'date': new Date().getTime()});
                localStorage.setItem('popups-seen', JSON.stringify(popupsSeenStorage));
            }
        }
    });
  }

  private initGA() {
    if (envs.gaCode) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${envs.gaCode}`;
      document.head.appendChild(script);

      gtag('js', new Date());
      gtag('config', envs.gaCode);
    }
  }

  private initFB() {
    if(envs.fbPixel) {
      fbq('init', envs.fbPixel);
      fbq('track', 'PageView');
    }
  }

  initSwiper() {
    jQuery('.swiper-container').append('<div class="swiper-button-next"></div><div class="swiper-button-prev"></div>');
    const mySwiper = (document.querySelector('.swiper-container') as any).swiper;
    jQuery('.swiper-button-next').click(() => {
      mySwiper.slideNext();
    });
    jQuery('.swiper-button-prev').click(() => {
      mySwiper.slidePrev();
    });
  }

  setGuideReady() {
    localStorage.setItem('visited', this.guideVersion);
    this.guideImages = [];
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.deeplinks.route({
        '/login': LoginPage,
        '/restaurant/:id': RestaurantPage,
      }).subscribe(match => {
        console.log('Match', match);
        this.router.navigate([match.$link.path], {queryParams: match.$args});
      }, nomatch => {
        console.log('Nomatch', nomatch);
      });
      if (navigator.userAgent.indexOf('FBAN') === -1 && navigator.userAgent.indexOf('FBAV') === -1) { // Nem Facebook in-app browser
        this.locMan.createListeners();
      }

      jQuery(window).on('popstate', function (event) {
        if (event.state !== null) {
          jQuery.fancybox.getInstance('close');
        }
      });

      // this.statusBar.styleDefault();
      if (this.platform.is('cordova')) {
        this.setPlatformListener();
      }

      this.statusBar.overlaysWebView(false);
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString('#be0101');
      if (Environment) {
        Environment.setEnv({
          'API_KEY_FOR_BROWSER_RELEASE': 'AIzaSyD948kltvx6zkngQIifpZMysyuyugTeSrM',
          'API_KEY_FOR_BROWSER_DEBUG': 'AIzaSyD948kltvx6zkngQIifpZMysyuyugTeSrM'
        });

      }


    });

  }

  private showCitySelectModal() {
    this.modalController.create({
      mode: 'ios',
      cssClass: 'mm-modal',
      component: MmCitySearchComponent,
      backdropDismiss: false,
      componentProps: {
        header: 'Település',
        searchLabel: 'Település kereső',
        searchPlaceholder: 'Adj meg írányítószámot vagy települést',
      },
    }).then(modal => {
      modal.onDidDismiss().then((r) => {
        let value = '';
        if (r.data) {
          value = r.data.RestaurantCity;
        }

        // lementjük a localStorage-be a kiválasztott várost
        // 5 napos érvényességgel
        const item = {
          value: value,
          expiry: new Date().getTime() + 5 * 24 * 60 * 60 * 1000
        };

        localStorage.setItem('cityClicked', JSON.stringify(item));
        gaEvent('clickCity', value);

        this.locMan.selectedLocation.next(value);

        this.showPopup();
      });
      return modal.present();
    });
  }

  setPlatformListener() {
    this.platform.pause.subscribe(() => {
      this.api.isForeground = false;
    });

    this.platform.resume.subscribe(() => {
      this.api.isForeground = true;
    });
  }

  @HostListener('document:ionBackButton', ['$event'])
  private overrideHardwareBackAction($event: any) {
    $event.detail.register(100, async () => {

      if (this.navHistory.length < 2) {
        if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
          navigator['app'].exitApp();
        } else {
          await this.toastService.presentToast(this.lang.transform('app.pressAgainToExit'));
          this.lastTimeBackPress = new Date().getTime();
        }
      } else {
        history.back();
      }

    });
  }
}
