import {Component, Input, NgZone, OnInit} from '@angular/core';
import {RestApiService} from "../rest-api.service";
import {ModalController} from "@ionic/angular";
import {Geocoder, GeocoderResult} from "@ionic-native/google-maps/ngx";

declare let gaEvent: Function;

@Component({
  selector: 'app-mm-locationsearch',
  templateUrl: './mm-locationsearch.component.html',
  styleUrls: ['./mm-locationsearch.component.scss'],
})
export class MmLocationsearchComponent {

  @Input() header: string = '';
  @Input() searchLabel: string = '';
  @Input() selectedFound: any = {};

  Founds: any[] = [];
  _selectedFound: any = {};
  myProfile: any = {};

  constructor(private api: RestApiService, private ngZone: NgZone, private modalController: ModalController) {
    this.onBlur();
  }

  onSearch(e) {
    this.selectedFound = {};
    this.api.searchAddress(e.target.value).subscribe(r => {
      this.Founds = r.data.Results;
    });
  }

  onItemClick(item) {

    Geocoder.geocode({'address': [item.Text, item.TextSecondary].join(', ')}).then((r: GeocoderResult[]) => {
      if (r.length) {
        gaEvent('searchByAddress', item.Text);
        item['location'] = r[0].position;
        this.selectedFound = item;
        this._selectedFound = item;
      }
    });
    /*item['location'] = {lat: 47, lng: 19};
    this.selectedFound = item;
    this._selectedFound = item;*/
  }

  onBlur() {
    this._selectedFound = {City: ''};
    setTimeout(() => {
      this._selectedFound = this.selectedFound;
    })
  }

  onCancel() {
    this.modalController.dismiss();
  }

  onFilter() {
    this.modalController.dismiss(this.selectedFound);
  }

}
