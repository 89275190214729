import { Component, Input, OnInit } from '@angular/core';
import {RestApiService} from '../rest-api.service';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../toast.service';

@Component({
  selector: 'app-restaurant-busy',
  templateUrl: './mm-restaurant-busy.component.html',
  styleUrls: ['./mm-restaurant-busy.component.scss'],
})
export class MmRestaurantBusyComponent implements OnInit {

  @Input() deliveryTime: string = '';

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    // Delivery time átalakítás 1:00 ról 60 perc formátumra
    const timeArray = this.deliveryTime.split(':');
    this.deliveryTime = (parseInt(timeArray[0]) * 60 + parseInt(timeArray[1])).toString();
  }

  closeModal() {
    this.modalController.dismiss(false);
  }

  save() {
    localStorage.setItem('busyRestaurantUserResponse', JSON.stringify([{'value': true, 'date': new Date().getTime()}]));

    this.modalController.dismiss(true);
  }
}
