import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'shortTime'
})
export class ShortTimePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!value) {
      return '';
    }
    const ret = value.split(':');
    return ret[0] + ':' + ret[1];
  }

}
