import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CanLoginGuard} from './can-login.guard';

const routes: Routes = [
  {path: 'login', loadChildren: './login/login.module#LoginPageModule', canActivate: [CanLoginGuard]},

  {path: '', loadChildren: './home/home.module#HomePageModule'},
  {path: '**', loadChildren: './not-found/not-found.module#NotFoundPageModule'},
  {path: '404', loadChildren: './not-found/not-found.module#NotFoundPageModule'},
  { path: 'change-password', loadChildren: './home/change-password/change-password.module#ChangePasswordPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
