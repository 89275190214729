import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MmPhoneVoteComponent} from "./mm-phone-vote.component";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    MmPhoneVoteComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    MmPhoneVoteComponent
  ]
})
export class MmPhoneVoteModule {
}
