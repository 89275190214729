import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MmPhoneComponent} from "./mm-phone.component";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    MmPhoneComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    MmPhoneComponent
  ]
})
export class MmPhoneModule {
}
