import {Component, Input} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';

@Component({
  selector: 'app-restaurant-pause',
  templateUrl: './mm-restaurant-pause.component.html',
  styleUrls: ['./mm-restaurant-pause.component.scss'],
})
export class MmRestaurantPauseComponent {

  @Input() phoneNumber: string = '';

  constructor(private modalController: ModalController,
              private platform: Platform) {
  }

  confirmModal() {
    localStorage.setItem('pausedRestaurantUserResponse', JSON.stringify([{'value': true, 'date': new Date().getTime()}]));
    this.modalController.dismiss(true);
  }

  callPhone() {
    if (this.platform.width() < 768) {
      window.open('tel:' + this.phoneNumber, '_system');
      return;
    }

    // Ha nem telefon akkor a gomb megnyomásra kicseréljük a gombot a telefonszámra .phone-button
    const phoneButton = document.querySelector('.phone-button');
    phoneButton.innerHTML = this.phoneNumber;
  }

  closeModal() {
    this.modalController.dismiss(false);
  }
}
