import {Injectable} from '@angular/core';
import {HeaderFilter} from './header-filter';
import {LocationFilter} from '../../location-filter';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  search: string = '';
  delivery: any = {};
  location: LocationFilter = {need: false, location: null};
  nearBy: boolean = false;
  nearLocation: boolean = false;
  dailyMenu: boolean = true;
  freeFilter: string[] = [];
  city: string = '';
  filterOpenAction: Subject<any> = new Subject<any>();

  constructor() {
  }
}
