import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {MmLocationsearchComponent} from "./mm-locationsearch.component";

@NgModule({
  declarations: [
    MmLocationsearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    MmLocationsearchComponent
  ],
})
export class MmLocationsearchModule {
}
