import {Injectable, OnDestroy} from '@angular/core';
import {Geolocation, Geoposition} from '@ionic-native/geolocation/ngx';
import {Subject, Subscription} from 'rxjs';

@Injectable()
export class LocationManagerService implements OnDestroy {

  public locChange: Subject<Geoposition> = new Subject();
  public locStatus: Subject<boolean> = new Subject();
  public currentPosition: Geoposition;
  public currentStatus: boolean;
  public selectedLocation: Subject<string>;

  public watch: Subscription;

  constructor(private geolocation: Geolocation) {
    this.selectedLocation = new Subject();
    this.locChange.subscribe(pos => {
      this.currentPosition = pos;
    });

    this.locStatus.subscribe(r => {
      this.currentStatus = r;
    });

  }

  createListeners() {
      this.watch = this.geolocation.watchPosition({maximumAge: 0, enableHighAccuracy: true}).subscribe(pos => {
        if (pos.timestamp) {
          this.locChange.next(pos);
          this.locStatus.next(true);
        } else { // Hiba történt
          this.locStatus.next(false);
        }
      }, e => {
        console.log(e);
        this.locStatus.next(false);
      });

  }

  ngOnDestroy(): void {
    if (this.watch) {
      this.watch.unsubscribe();
    }
  }
}
