// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.api.mai.menu',
  imageUrl: 'https://dev.img.mai.menu/',
  gaCode: null,
  fbPixel: null,
  firebaseVapid: 'BBaBUiy4dkM-Rd8MX-FyYCsyrURRldvYAB4G58J2sGO8L5KdhuULVVeRlmRaZ9Twx2db8IQO04-q2ObrN0r0ySo',
  firebaseAppInit: {
    apiKey: "AIzaSyBzgvpTphmRfxqjmRV8SU5d2il9QYBPX_Y",
    authDomain: "mai-menu-dev.firebaseapp.com",
    projectId: "mai-menu-dev",
    storageBucket: "mai-menu-dev.appspot.com",
    messagingSenderId: "689765668143",
    appId: "1:689765668143:web:4075562733f5a122037dda"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
