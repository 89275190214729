import {Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Restaurant} from '../api/restaurant';
import {RestaurantMenu} from '../api/restaurant-menu';
import {RestApiService} from '../rest-api.service';
import {Router} from '@angular/router';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';
import {RestaurantDish} from '../api/restaurant-dish';
import {RestaurantDishCategory} from '../api/restaurant-dish-category';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { MmRestaurantPauseComponent } from '../mm-restaurant-pause/mm-restaurant-pause.component';
import {
  MmRestaurantBusyComponent
} from '../mm-restaurant-busy/mm-restaurant-busy.component';


declare let gaEvent: Function;

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  providers: [InAppBrowser]
})
export class CardComponent implements OnInit, OnChanges {
  @Input() closeButton: boolean = false;
  @Input() cardInput: Restaurant;
  @Input() needDetails: boolean = true;
  @Input() hideCallInfoMenuAndPrice: boolean = false;
  @Input() date: any = moment();
  @Input() onlyCart: boolean = false;
  @Input() searchText: string = '';

  @Output() closeClick = new EventEmitter();
  @Output() onNavClick = new EventEmitter();
  @Output() onFavoriteChange = new EventEmitter();

  filter: boolean = false;
  delivery: boolean = false;
  nearBy: boolean = false;
  nearLocation: boolean = false;

  starterMeals: RestaurantMenu[] = [];
  mainMeals: RestaurantMenu[] = [];
  finishMeals: RestaurantMenu[] = [];
  garnishMeals: RestaurantMenu[] = [];
  picklesMeals: RestaurantMenu[] = [];
  hasMeals: boolean = false;

  needVariant: boolean = false;

  isOrderAble: boolean = false;

  imageHost = environment.imageUrl;

  mealLoop = [
    {mealName: 'starterMeals', icon: 'fas fa-soup'},
    {mealName: 'mainMeals', icon: 'fas fa-turkey'},
    {mealName: 'finishMeals', icon: 'fas fa-pie'},
    {mealName: 'garnishMeals', icon: 'fas fa-french-fries'},
    {mealName: 'picklesMeals', icon: 'fas fa-salad'}
  ];

  highlightedDishes = [];
  highlightedCategories = [];

  cheapestDeliveryPrice: Number|null = null;

  constructor(private router: Router,
              private ngZone: NgZone,
              private api: RestApiService,
              private inapp: InAppBrowser,
              private sanitizer: DomSanitizer,
              private modalController: ModalController) {
  }

  ngOnInit() {}

  saveFavorite(e) {
    e.stopPropagation();
    this.api.postFavorite(this.cardInput.Info.RestaurantLink).subscribe(r => {

      if (r.success) {
        this.cardInput.IsFavorite = r.data.Favorite;
        this.onFavoriteChange.emit();
        if (this.cardInput.IsFavorite) {
          this.saveEvent('saveFavorite');
        }
      }
    });
  }

  closeClickHandler(e) {
    e.stopPropagation();
    this.closeClick.emit();
  }

  saveEvent(eventName) {
    gaEvent('restaurant-' + eventName, this.cardInput.Info.RestaurantLink);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cardInput) {
      this.needVariant = false;
      this.starterMeals = [];
      this.mainMeals = [];
      this.finishMeals = [];
      this.garnishMeals = [];
      this.picklesMeals = [];
      let dishCount = 0;

      for (const i of (this.cardInput.Menu || [])) {
        if (i.MenuVariation !== '') {
          this.needVariant = true;
        }
        switch (i.DishType) {
          case 'starter':
            this.starterMeals.push(i);
            break;
          case 'main':
            this.mainMeals.push(i);
            break;
          case 'dessert':
            this.finishMeals.push(i);
            break;
          case 'garnish':
            this.garnishMeals.push(i);
            break;
          case 'pickles':
            this.picklesMeals.push(i);
            break;
        }
        dishCount++;
      }

      this.hasMeals = (this.starterMeals.length + this.mainMeals.length + this.finishMeals.length + this.garnishMeals.length + this.picklesMeals.length) > 0;

      if (!this.cardInput.IsAd) {
        if (moment().isSame(this.date, 'day')) {
          this.isOrderAble = moment().isBetween(
              // moment(moment().format('YYYY-MM-DD ') + this.cardInput.Open.MenuOpen),
              moment(moment().format('YYYY-MM-DD ') + '00:00:00'),
              moment(moment().format('YYYY-MM-DD ') + this.cardInput.Open.OrderMenuTo)
          );
        } else {
          this.isOrderAble = true;
        }
        this.searchHighlightedDishes();

      }

      if (this.cardInput.Delivery.length) {
        // A legolcsóbb szállítási mód árát megkeressük, maxról indítva, mert annál biztosan kisebb lesz az első elem
        this.cheapestDeliveryPrice = this.cardInput.Delivery.reduce(
            (cheapestPrice, delivery) => Math.min(delivery.DeliveryCost, cheapestPrice),
            Number.MAX_VALUE
        );
      } else {
        this.cheapestDeliveryPrice = null;
      }
    }
  }

  onDishFoundClick(event, dish: RestaurantDish) {
    event.stopPropagation();
    event.preventDefault();
    this.showModals();
    this.router.navigate(['/restaurant', this.cardInput.Info.RestaurantLink, dish.DishID]);
  }

  onCategoryFoundClick(event, cat: RestaurantDishCategory) {
    event.stopPropagation();
    event.preventDefault();
    this.showModals();
    this.router.navigate(['/restaurant', this.cardInput.Info.RestaurantLink],
        {queryParams: {cat: cat.CategoryName}, state: {type: 'carte', cat: cat}});
  }

  latinalize(str: string) {
    const repo = {
      'á': 'a',
      'é': 'e',
      'ű': 'u',
      'ő': 'o',
      'ú': 'u',
      'ö': 'o',
      'ü': 'u',
      'ó': 'o',
      'í': 'i',
    };
    return str.toLowerCase().replace(/./gi, m => repo[m] || m);
  }

  searchHighlightedDishes() {
    this.highlightedDishes = [];
    this.highlightedCategories = [];

    const searchText = this.latinalize(this.searchText);

    if (this.searchText) {
      for (let cat of this.cardInput.Categories) {
        for (let dish of cat.Dishes) {
          const dishName = this.latinalize(dish.DishName);
          if (dishName.includes(searchText)) {
            this.highlightedDishes.push(dish);
          }
        }
        for (let childCat of cat.Items) {
          for (let dish of childCat.Dishes) {
            const dishName = this.latinalize(dish.DishName);
            if (dishName.includes(searchText)) {
              this.highlightedDishes.push(dish);
            }
          }
          const catName = this.latinalize(childCat.CategoryName);

          if (catName.includes(searchText)) {
            this.highlightedCategories.push(childCat);
          }
        }

        const catName = this.latinalize(cat.CategoryName);

        if (catName.includes(searchText)) {
          this.highlightedCategories.push(cat);
        }
      }
      this.cardInput.Categories[0].Dishes;
    }
  }

  navigateToRestaurant(link) {
    this.showModals();
    this.onNavClick.emit();
    gaEvent('clickRestaurant', link);
    this.router.navigate(['/restaurant/', link],
        {state: {date: this.date.format('YYYY-MM-DD'), type: this.onlyCart ? 'carte' : 'menu'}});
  }

  nav() {
    gaEvent('clickAd', this.cardInput.Info.AdLink);
    this.onNavClick.emit();
    if (this.cardInput.Info.RestaurantLink) {
      this.api.sendAdsTrackingEvent(this.cardInput.Info.AdLink).subscribe(r => {

      });
      this.router.navigate(['/restaurant', this.cardInput.Info.RestaurantLink], {
        state: {
          date: this.date.format('YYYY-MM-DD'),
          type: this.cardInput.Info.AdRestaurantView, //this.onlyCart ? 'carte' : 'menu'
        }
      });
    } else {
      this.inapp.create(this.api.apiHost + this.api.apiBase + this.cardInput.Info.AdLink, '_system');
    }
  }

  getBackgroundUrl() {
    return this.sanitizer.bypassSecurityTrustStyle('url(' +
        (this.cardInput.Info.RestaurantAvatar ? (environment.imageUrl + this.cardInput.Info.RestaurantAvatar + '?h=100&w=100') : undefined)
        + ')');
  }

  openCarte(e) {
    e.stopPropagation();
    e.preventDefault();
    this.onNavClick.emit();
    this.router.navigate(['/restaurant/', this.cardInput.Info.RestaurantLink],
        {state: {date: this.date.format('YYYY-MM-DD'), type: 'carte'}});
  }

  getBackgroundUrlDish(dish: RestaurantDish) {
    if (dish.DishImage) {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + environment.imageUrl + (dish.DishImage) + '?w=400)');
    }
    return '';
  }

  openDish(e,dish: RestaurantDish) {
    e.preventDefault();
    e.stopPropagation();

    this.router.navigate(['/restaurant', this.cardInput.Info.RestaurantLink, dish.DishID], {state: {type: 'carte'}});
  }

  showModals() {
    if ((this.cardInput.Info.RestaurantStatus === 'PAUSED' && JSON.parse(localStorage.getItem('pausedRestaurantUserResponse')) === null) ||
        (this.cardInput.Info.RestaurantStatus === 'BUSY' && JSON.parse(localStorage.getItem('busyRestaurantUserResponse')) === null)) {

      if (this.cardInput.Info.RestaurantStatus === 'PAUSED' && JSON.parse(localStorage.getItem('pausedRestaurantUserResponse')) === null) {
        this.modalController.create({
          mode: 'ios',
          cssClass: 'mm-restaurant-pause-modal',
          component: MmRestaurantPauseComponent,
          componentProps: {
            phoneNumber: this.cardInput.Info.RestaurantPhone,
          },
        }).then(modal => {
          modal.present();
        });
      }
      if (this.cardInput.Info.RestaurantStatus === 'BUSY' && JSON.parse(localStorage.getItem('busyRestaurantUserResponse')) === null) {
        // Ha a felhasználó elfogadja akkor visszatérünk true-val egyébként false-al
        this.modalController.create({
          mode: 'ios',
          cssClass: 'mm-restaurant-busy-modal',
          component: MmRestaurantBusyComponent,
          componentProps: {
            deliveryTime: this.cardInput.Info.RestaurantDeliveryTime,
          },
        }).then(modal => {
          modal.present();
        });
      }
    }
  }
}
