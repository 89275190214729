import {Component, Input, NgZone, OnInit} from '@angular/core';
import {RestApiService} from "../rest-api.service";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-mm-city-zip-search',
  templateUrl: './mm-city-zip-search.component.html',
  styleUrls: ['./mm-city-zip-search.component.scss'],
})
export class MmCityZipSearchComponent {

  @Input() header: string = '';
  @Input() selectedFound: Object = {City: ''};
  @Input() searchLabel: string = '';
  @Input() searchPlaceholder: string = '';

  Founds: Array<string>[] = [];
  _selectedFound: Object = {City: ''};

  constructor(private api: RestApiService, private ngZone: NgZone, private modalController: ModalController) {
    this.onBlur();
  }

  onSearch(e: KeyboardEvent) {
    this.selectedFound = {};
    this.api.searchZipCode(e.target['value']).subscribe(r => {
      this.Founds = r.data;
    });
  }

  onItemClick(item: Array<string>) {
    this.selectedFound = item;
    this._selectedFound = item;
  }

  onBlur() {
    this._selectedFound = {City: ''};
    setTimeout(() => {
      this._selectedFound = this.selectedFound;
    })
  }

  onCancel() {
    this.modalController.dismiss();
  }

  onFilter() {
    this.modalController.dismiss(this.selectedFound);
  }
}
