import {
  Compiler,
  Component,
  ComponentRef,
  Injector, Input, NgModule,
  NgModuleRef, OnChanges, OnDestroy,
  OnInit, SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';


@Component({
  selector: 'app-dynamic-html',
  template: '<div #vc></div>'
})
export class DynamicHtmlComponent implements OnChanges, OnDestroy {

  @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;
  @Input() html = '';
  @Input() variables: any;
  private cmpRef: ComponentRef<any>;

  constructor(private compiler: Compiler,
              private injector: Injector,
              private moduleRef: NgModuleRef<any>
  ) {

  }

  private createComponentFromRaw(template: string) {
    this.vc.clear();

    const tmpCmp = Component({template})(class {

      data: any = {};
      dateNow = Date.now();

      constructor() {

      }

      ngOnInit() {

      }
    });

    const tmpModule = NgModule({
      imports: [RouterModule, CommonModule],
      declarations: [tmpCmp],

    })(class {
    });


    this.compiler.compileModuleAndAllComponentsAsync(tmpModule)
      .then((componentFactories) => {

        const compFactory = componentFactories.componentFactories[1];

        this.cmpRef = compFactory.create(this.injector, [], null, this.moduleRef);
        this.cmpRef.instance.name = 'my-dynamic-component';
        this.cmpRef.instance.data = this.variables;
        this.vc.insert(this.cmpRef.hostView);
      });
  }

  ngOnDestroy() {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.html) {
      if (this.cmpRef) {
        this.cmpRef.destroy();
      }
      this.createComponentFromRaw(this.html);
    }
  }
}
