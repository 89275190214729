import {Component, NgZone, OnInit} from '@angular/core';
import {RestApiService} from '../rest-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Platform } from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {FacebookLoginResponse} from '@ionic-native/facebook/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {TranslatePipe} from '../translate.pipe';
import {Subscription} from 'rxjs';
import isWebview from 'is-ua-webview';
import {ToastService} from '../toast.service';

declare let gaEvent: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  username: string = '';
  password: string = '';

  currentPage: string = '';

  resetPasswordToken: string = null;

  regFirstName: string = '';
  regLastName: string = '';
  regEmail: string = '';
  regPassword: string = '';
  regRePassword: string = '';
  regAcceptTerm: boolean = false;
  showPassword: boolean = false;
  showPassword2: boolean = false;

  captchaSub: Subscription = null;

  constructor(private api: RestApiService,
              private router: Router,
              private arouter: ActivatedRoute,
              private fb: Facebook,
              private ngZone: NgZone,
              private lang: TranslatePipe,
              private googlePlus: GooglePlus,
              // private recaptchaV3Service: ReCaptchaV3Service,
              private toastService: ToastService,
              private splashScreen: SplashScreen,
              private platform: Platform) {
    this.arouter.queryParams.subscribe(p => {
      console.log(p);
      if (p['reset-password']) {
        setTimeout(() => {
          this.currentPage = 'reset-password';
          this.resetPasswordToken = p['reset-password'];
        }, 0);
      }
    });
    this.api.myProfile$.subscribe(p => {
      if (p.MemberToken) {
        if (p.MemberCorporate === null || p.MemberCorporate === undefined) {
          this.router.navigate(['/profile'], {replaceUrl: true});
        } else {
          this.router.navigate(['/'], {replaceUrl: true});
        }
      }
    });
  }

  ionViewDidEnter() {
    this.splashScreen.hide();
    if (history.state.reg) {
      this.currentPage = 'register';
    } else {
      this.currentPage = '';
    }
    /*let element = document.getElementsByClassName('grecaptcha-badge');
    element[0].setAttribute('id', 'grecaptcha_badge');
    document.getElementById('grecaptcha_badge').style.display = '';*/
  }

  ionViewWillLeave() {
    /*let element = document.getElementsByClassName('grecaptcha-badge');
    element[0].setAttribute('id', 'grecaptcha_badge');
    document.getElementById('grecaptcha_badge').style.display = 'none';
    if (this.captchaSub){
      this.captchaSub.unsubscribe();
    }*/
  }

  ngOnInit() {

  }

  login() {
    if (this.username.trim() && this.password.trim()) {
      //this.captchaSub = this.recaptchaV3Service.execute('login').subscribe(token => {
      this.api.login(this.username, this.password, '').subscribe(r => {
        gaEvent('loginAction', 'login');
      });
      //});
    } else {
      this.toastService.presentToast('Érvénytelen hitelesítő adatok');
    }

  }

  doRegister() {
    if (this.regLastName.trim() && this.regFirstName.trim() && this.regEmail.trim() && this.regPassword === this.regRePassword && this.regPassword.trim() && this.regAcceptTerm) {
      //this.captchaSub = this.recaptchaV3Service.execute('register').subscribe(token => {
      this.api.register(this.regFirstName, this.regLastName, this.regEmail, this.regPassword, true, '').subscribe(r => {
        gaEvent('loginAction', 'register');
        this.currentPage = '';
      }, e => {
        this.toastService.presentToast(e.error.error.message);
      });
      //});

    } else {
      this.toastService.presentToast(this.lang.transform('loginPage.passwordMissmatch'));
    }
  }

  doResetPassword() {
    if (this.regPassword === this.regRePassword && this.regPassword.trim()) {
      //this.captchaSub = this.recaptchaV3Service.execute('resetpassword').subscribe(token => {
      this.api.resetPassword(this.resetPasswordToken, this.regPassword, '').subscribe(r => {
        gaEvent('loginAction', 'reset-password');
        this.toastService.presentToast(this.lang.transform('loginPage.successPasswordChange'));
        this.currentPage = '';
      }, e => {
        this.toastService.presentToast(e.error.error.message);
      });
      //});
    } else {
      this.toastService.presentToast(this.lang.transform('loginPage.passwordMissmatch'));
    }
  }

  enterWithoutLogin() {
    this.api.isVisitor = true;
    gaEvent('loginAction', 'enterWithoutLogin');
    this.router.navigate(['/']);
  }

  doForgot() {
    //this.captchaSub = this.recaptchaV3Service.execute('forget').subscribe(token => {
    this.api.forget(this.regEmail, '').subscribe(r => {
      gaEvent('loginAction', 'forgetPassword');
      if (r.success) {
        this.currentPage = '';
        this.toastService.presentToast(r.data.Message).then();
      }
    });
    //});
  }

  facebookLogin() {

    this.fb.login(['public_profile', 'email'])
      .then((res: FacebookLoginResponse) => {
        this.api.loginFacebook(res.authResponse.accessToken).subscribe(r => {

        });
      })
      .catch(e => console.log('Error logging into Facebook', e));
  }

  googleLogin() {
    this.googlePlus.login({}).then(res => {
      this.api.loginGoogle(res.accessToken).subscribe(r => {

      });
    })
    .catch(e => console.log('Error logging into Google', e));
  }

  isWebView() {
    return isWebview(navigator.userAgent) &&
        (!this.platform.is('cordova') || window.cordova.platformId === 'browser'); // Az alkalmazás is webviewt használ
  }
}
