import {Component, ElementRef, Input, NgZone, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {Restaurant} from '../api/restaurant';
import {RestaurantMenu} from '../api/restaurant-menu';
import {NewOrder} from '../api/new-order';
import {OrderService} from '../order.service';
import * as moment from 'moment';
import {OrderItem} from '../api/order-item';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {MmRestaurantPauseComponent} from '../mm-restaurant-pause/mm-restaurant-pause.component';
@Component({
  selector: 'app-dish-list',
  templateUrl: './dish-list.component.html',
  styleUrls: ['./dish-list.component.scss'],
})
export class DishListComponent implements OnChanges {
  @Input() dishInput: Restaurant;
  @Input() date: any = new Date();

  selectedDate: string = moment().format('YYYY-MM-DD');

  onlyPhone: boolean;

  allMealsGrouped: { [type: string]: {
    icon: string,
    meals: RestaurantMenu[]
  } } = {
    starter: { icon: 'fa-soup', meals: [] },
    main: { icon: 'fa-turkey', meals: [] },
    dessert: { icon: 'fa-pie', meals: [] }, // Finish
    garnish: { icon: 'fa-french-fries', meals: [] },
    pickles: { icon: 'fa-salad', meals: [] }
  };

  get allMealData() {
    return Object.values(this.allMealsGrouped);
  }

  needVariant: boolean = false;

  myCart: NewOrder = {Groups: []} as NewOrder;
  subTotal: number = 0;
  isOrderAble: boolean = false;
  hasMeals: boolean = false;

  constructor(
    private cart: OrderService,
    private ngZone: NgZone,
    private router: Router,
    private modalController: ModalController
  ) {
    this.cart.CartSubject.subscribe(r => {
      this.myCart = r;
    });
    this.myCart = this.cart.Cart;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dishInput) {
      this.onlyPhone = this.dishInput.Info.RestaurantServices.indexOf('phone') !== -1;
      this.selectedDate = moment(this.date).format('YYYY-MM-DD');
      this.isOrderAble = false;
      this.needVariant = false;
      for (const meal of this.allMealData) {
        meal.meals = [];
      }
      let dishCount = 0;

      for (const i of (this.dishInput.Menu || [])) {
        if (i.MenuVariation !== '') {
          this.needVariant = true;
        }
        const ordered = this.searchInCart(i.DishID);
        if (ordered) {
          i.DishQty = (ordered as OrderItem).DishQty;
        } else {
          i.DishQty = 0;
        }

        this.allMealsGrouped[i.DishType].meals.push(i);
        if (moment(this.date).isSame(new Date(), 'day')) {
          this.isOrderAble = moment().isBetween(
            // moment(moment().format('YYYY-MM-DD ') + restaurant.Open.MenuOpen),
            moment(moment().format('YYYY-MM-DD ') + '00:00:00'),
            moment(moment().format('YYYY-MM-DD ') + this.dishInput.Open.OrderMenuTo)
          );
        } else {
          this.isOrderAble = true;
        }
        dishCount++;
      }
      this.calculateSubTotal();

      this.hasMeals = this.allMealData.reduce(
        (mealCount, mealType) => mealCount += mealType.meals.length, 0
      ) > 0;
    }


  }

  searchInCart(dishId: number): OrderItem | boolean {
    for (const i of this.cart.Cart.Groups) {
      if ((i.RestaurantID === this.dishInput.Info.RestaurantID) && (i.DishType === 'menu') && (i.DeliveryDate === this.selectedDate)) {
        for (const d of i.Items) {
          if (d.DishID === dishId) {
            return d;
          }
        }
      }
    }
    return false;
  }

  changeMenuQty(menu: RestaurantMenu, increase: boolean) {
    if (this.dishInput.Info.RestaurantStatus === 'PAUSED') {
      this.modalController.create({
        mode: 'ios',
        cssClass: 'mm-restaurant-pause-modal',
        component: MmRestaurantPauseComponent,
        componentProps: {
          phoneNumber: this.dishInput.Info.RestaurantPhone,
        },
      }).then(modal => {
        return modal.present();
      });
    } else {
      if (increase) {
        this.ngZone.run(() => {
          menu.DishQty = menu.DishQty + 1;
        });
        const m = Object.assign({}, menu);
        m.DishQty = 1;
        if (!this.cart.addDishToCart(this.dishInput, m, 'menu', this.selectedDate)) {
          menu.DishQty = menu.DishQty - 1;
        }
      } else {
        this.ngZone.run(() => {
          menu.DishQty = menu.DishQty - 1;
        });
        const m = Object.assign({}, menu);
        m.DishQty = -1;
        if (!this.cart.addDishToCart(this.dishInput, m, 'menu', this.selectedDate)) {
          menu.DishQty = menu.DishQty + 1;
        }
      }

      this.calculateSubTotal();
    }
  }

  calculateSubTotal() {
    this.ngZone.run(() => {
      this.subTotal = this.allMealData.reduce((pv, cv) => pv.concat(cv.meals), <RestaurantMenu[]>[])
        .map(dish => dish.DishPrice * dish.DishQty).reduce((pv, cv) => pv + cv, 0);
    });
  }

  navToCart() {
    this.router.navigate(['/order']);
  }
}
