import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MmRestaurantBusyComponent} from './mm-restaurant-busy.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    MmRestaurantBusyComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    MmRestaurantBusyComponent
  ]
})
export class MmRestaurantBusyModule {
}
