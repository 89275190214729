import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Observable, Subject, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HTTP} from '@ionic-native/http/ngx';
import {catchError} from 'rxjs/operators';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';
import {Restaurant} from './api/restaurant';
import {ServerResponse} from './api/server-response';
import {RestaurantList} from './api/restaurant-list';
import {StaticPage} from './api/static-page';
import {Member} from './api/member';
import { v4 as uuidv4 } from 'uuid';
import {SearchAddresses} from './api/search-address';
import {MenuResult} from './api/menu';
import {Address} from './api/address';
import {AddressList} from './api/address-list';
import {RestaurantDish} from './api/restaurant-dish';
import {OrderList} from './api/order-list';
import {NewOrder} from './api/new-order';
import {FullOrder} from './api/full-order';
import {OrderResponse} from './api/orderResponse';
import {Coupon} from './api/coupon';
import {environment as envs, environment} from '../environments/environment';
import {Feedback} from './api/feedback';
import {ToastService} from './toast.service';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { makeNotification, makeNotificationUrl } from "./push.model";

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  public canGoBack: boolean = false;
  public myProfile$: Subject<Member> = new Subject();

  public myProfile: Member = <Member>{};
  public hasProfile: boolean = false

  public apiHost = environment.apiUrl;
  public apiBase = '/v1';
  private readonly selfUUID = null;

  public isForeground: boolean = true;

  private readonly mobile = false;
  httpOptions = {headers: {}};

  public isVisitor: boolean = false;

  public guideSubject: Subject<any> = new Subject<any>();

  constructor(private platform: Platform,
              private http: HttpClient,
              private httpNativ: HTTP,
              private toastService: ToastService) {

    this.mobile = false;
    this.selfUUID = uuidv4();

    // Firebase init és PUSH üzenet kezelés regisztrációja
    firebase.initializeApp(envs.firebaseAppInit);

    try {
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        if (payload.data && Notification.permission === "granted") {
          const notification = makeNotification(payload),
              instance = new Notification(notification.title || 'Mai Menü', notification);

          instance.onclick = (event) => {
            event.preventDefault();
            instance.close();

            // Csakis 'openLink' akciókat kezelünk jelenleg és megnyitjuk a küldött linket
            const url = makeNotificationUrl(payload);
            if (url) window.open(url, '_blank');
          }
        }
      });
    } catch(e) {
      console.warn("PUSH not working, due: ", e)
    }

    this.myProfile$.subscribe(p => {
      this.hasProfile = Object.keys(p).length > 0;
      this.myProfile = p;

      if (p.MemberToken) {
        this.httpOptions.headers['X-API-KEY'] = p.MemberToken;

        // Minden belépéskor újrakérdezzük a PUSH tokent, hogy a megfelelő felhasználóhoz legyen regisztrálva backenden is
        this.requestNotificationToken().then(async(token) => {
          if( token ) {
            const response = await this.sendPushToken(token).toPromise();

            this.myProfile.DeviceID = response.data.DeviceID;
            localStorage.setItem('profile', JSON.stringify(this.myProfile));
          }
        });

      } else {
        this.httpOptions.headers = {};
      }

      localStorage.setItem('profile', JSON.stringify(p));
    });

    const oldProfile = localStorage.getItem('profile');
    if (oldProfile) this.myProfile$.next(JSON.parse(oldProfile));
  }

  protected async requestNotificationToken() {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted' && this.hasProfile) {
        const messaging = firebase.messaging();
        return await messaging.getToken({vapidKey: envs.firebaseVapid})
      }
    } catch(error) {
      console.warn('Error getting PUSH token:', error);
    }
  }

  private doRequest(method, path, data, showError = true): Observable<any> {
    let url = `${this.apiHost}${this.apiBase}${path}`;
    // let url = this.mobile ? `${this.apiHost}${this.apiBase}${path}` : `${this.apiBase}${path}`;
    if (this.mobile) {
      this.httpNativ.setDataSerializer('json');
    }

    switch (method) {
      case 'get':
        const getParams = this.genGETparams(data);
        if (getParams) {
          url += '?' + getParams;
        }

        return (this.mobile ?
          from(this.httpNativ.get(url, {}, this.httpOptions.headers)).pipe(map((r) => {
            try {
              return JSON.parse(r.data);
            } catch (e) {
              console.error( e, r);
            }

          }))
          : this.http.get(url, this.httpOptions)).pipe(catchError((e) => {
          return this.errorHandler(e, showError);
        }));
      case 'delete':
        return (this.mobile ?
          from(this.httpNativ.delete(url, {}, this.httpOptions.headers)).pipe(map((r) => {
            try {
              return JSON.parse(r.data);
            } catch (e) {
              console.error( e, r);
            }
          }))
          : this.http.delete(url, this.httpOptions)).pipe(catchError((e) => {
          return this.errorHandler(e, showError);
        }));
      case 'put':
        return (this.mobile ?
          from(this.httpNativ.put(url, data, this.httpOptions.headers)).pipe(map((r) => {
            try {
              return JSON.parse(r.data);
            } catch (e) {
              console.error( e, r);
            }

          }))
          : this.http.put(url, data, this.httpOptions)).pipe(catchError((e) => {
          return this.errorHandler(e, showError);
        }));
      case 'post':
        return (this.mobile ?
          from(this.httpNativ.post(url, data, this.httpOptions.headers)).pipe(map((r) => {
            try {
              return JSON.parse(r.data);
            } catch (e) {
              console.error( e, r);
            }
          }))
          : this.http.post(url, data, this.httpOptions)).pipe(
              catchError((e) => {
                return this.errorHandler(e, showError);
              })
        );
      default:
        console.warn('Nincs ilyen method!');
    }
    return new Observable();
  }

  private errorHandler(error: any, showError: boolean) {
    if (showError) {
      if (error.status === 0) {
        this.toastService.presentToast('Nincs internetkapcsolat, kérlek ellenőrizd!').then(() => {

        });
      }
      if (error.status === 401) {
        this.myProfile$.next({} as Member);
        this.toastService.presentToast('Kérjük jelentkezz be a funkció használatához!').then(() => {
        });
      }
      if (error.status === 403) {
        this.toastService.presentToast('Nincs jogod').then(() => {
        });
      }
      if (error.status === 500) {
        this.toastService.presentToast('Kiszolgáló hiba').then(() => {
        });
      }
      if (error.status === 404) {
        this.toastService.presentToast('Nem található').then(() => {
        });
      }
      if (error.status === 400) {
        this.toastService.presentToast(error.error.error.message).then(() => {
        });
      }
    }

    return throwError(error);
  }


  private genGETparams(data) {
    const ret = [];
    for (const i of Object.keys(data)) {
      if ((data[i] !== undefined) && (data[i] !== '')) {
        ret.push(i);
      }
    }
    return ret.map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
  }

  public getRestaurantList(datetime: string, Location?: string, Delivery?: string, Page?: number, PerPage?: number, Search?: string, Radius?: number, hasCarte?: boolean, FreeFilter?: string[], City?: string, OnlyDelivery?: boolean): Observable<ServerResponse<RestaurantList>> {
    return this.doRequest('get', `/restaurant/list/`, {
      'Date': datetime,
      'Location': Location,
      'Page': Page,
      'PerPage': PerPage,
      'Search[DeliveryZip]': Delivery,
      'Search[String]': Search,
      'Search[FreeFilter]': FreeFilter,
      'Search[OnlyDelivery]': OnlyDelivery,
      'Radius': Radius,
      'HasCarte': hasCarte,
      'City': City,
    });
  }

  public getRestaurantFavoriteList(datetime: string, Page: number, PerPage: number): Observable<ServerResponse<RestaurantList>> {
    return this.doRequest('get', `/restaurant/list/`, {
      'Favorite': true,
      'Date': datetime,
      'Page': Page,
      'PerPage': PerPage
    });
  }

  public postFavorite(id): Observable<ServerResponse<any>> {
    return this.doRequest('post', `/restaurant/${id}/favorite/`, {});
  }

  public loadRestaurant(id, datetime, Location?: string): Observable<ServerResponse<Restaurant>> {
    return this.doRequest('get', `/restaurant/${id}/`, {'Date': datetime, 'Location': Location});
  }

  public getPage(link, showError = true, city?: string): Observable<ServerResponse<StaticPage>> {
    return this.doRequest('get', `/page/${link}/`, {'city': city}, showError);
  }

  public getPopups(city?: string, returningUser = false): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/popup/${city}/`, {'returningUser': returningUser});
  }

  public login(username, password, _recaptcha): Observable<ServerResponse<Member>> {
    return this.doRequest('post', '/login/', {
      MemberEmail: username,
      MemberPassword: password,
      _recaptcha
    }).pipe(map(r => {
      if (r.success) {
        this.myProfile$.next(r.data);
      } else {
        this.myProfile$.next(<Member>{});
      }

      return r;
    }), catchError((e) => {
      this.myProfile$.next(<Member>{});
      return throwError(e);
    }));
  }

  public loginFacebook(AccessToken: string): Observable<ServerResponse<Member>> {
    return this.doRequest('post', '/login/facebook/', {
      AccessToken
    }).pipe(map(r => {
      if (r.success) {
        this.myProfile$.next(r.data);
      } else {
        this.myProfile$.next(<Member>{});
        this.toastService.presentToast('Helytelen bejelentkezési adatokat adott meg!');
      }

      return r;
    }), catchError((e) => {
      this.myProfile$.next(<Member>{});
      this.toastService.presentToast('Helytelen bejelentkezési adatokat adott meg!').then();
      return throwError(e);
    }));
  }

  public loginGoogle(AccessToken: string): Observable<ServerResponse<Member>> {
    return this.doRequest('post', '/login/google/', {
      AccessToken
    }).pipe(map(r => {
      if (r.success) {
        this.myProfile$.next(r.data);
      } else {
        this.myProfile$.next(<Member>{});
        this.toastService.presentToast('Helytelen bejelentkezési adatokat adott meg!');
      }

      return r;
    }), catchError((e) => {
      this.myProfile$.next(<Member>{});
      this.toastService.presentToast('Helytelen bejelentkezési adatokat adott meg!').then();
      return throwError(e);
    }));
  }


  public resetPassword(resetPasswordToke: string, newPassword: string, _recaptcha: string): Observable<ServerResponse<any>> {
    return this.doRequest('post', `/reset-password/${resetPasswordToke}/`, {newPassword, _recaptcha});
  }

  public register(firstName: string, lastName: string, email: string, password: string, terms: boolean, _recaptcha: string): Observable<ServerResponse<Member>> {
    this.myProfile$.next(<Member>{});
    return this.doRequest('post', '/register/', {
      MemberEmail: email,
      MemberPassword: password,
      MemberFirstName: firstName,
      MemberLastName: lastName,
      Terms: terms,
      _recaptcha
    }).pipe(map(r => {
      if (r.success) {
        this.myProfile$.next(r.data);
      } else {
        this.myProfile$.next(<Member>{});
      }
      return r;
    }));
  }

  public logout(): Observable<ServerResponse<any>> {
    return this.doRequest('post', '/logout/', {DeviceID: this.myProfile.DeviceID}).pipe(map(r => {
      this.myProfile$.next(<Member>{});
      return r;
    }));
  }

  public forget(email: string, _recaptcha: string): Observable<ServerResponse<any>> {
    return this.doRequest('post', '/forgot/', {MemberEmail: email, _recaptcha});
  }

  sendPushToken(token): Observable<ServerResponse<any>> {
    const deviceId = this.myProfile.DeviceID;

    let platform = null;
    if (this.platform.is('android')) {
      platform = 'android';
    } else if (this.platform.is('ios')) {
      platform = 'ios';
    }

    const params = {
      DeviceToken: token,
      DeviceID: deviceId,
      DevicePlatform: platform
    };

    return this.doRequest('post', '/profile/device/', params);
  }

  public searchAddress(text: string): Observable<ServerResponse<SearchAddresses>> {
    return this.doRequest('get', '/search/autocomplete/', {Input: text, Token: this.selfUUID});
  }

  public loadMenu(key: string): Observable<ServerResponse<MenuResult>> {
    return this.doRequest('get', `/menu/${key}/`, {});
  }

  public getAddresses(): Observable<ServerResponse<AddressList>> {
    return this.doRequest('get', '/profile/addresses/', {});
  }

  public newAddress(model: Address): Observable<ServerResponse<any>> {
    return this.doRequest('post', '/profile/address/', model);
  }

  public updateAddress(model: Address): Observable<ServerResponse<any>> {
    return this.doRequest('put', `/profile/address/${model.AddressID}/`, model);
  }

  public deleteAddress(model: Address): Observable<ServerResponse<any>> {
    return this.doRequest('delete', `/profile/address/${model.AddressID}/`, {});
  }

  public loadAddress(AddressID: number): Observable<ServerResponse<Address>> {
    return this.doRequest('get', `/profile/address/${AddressID}/`, {});
  }

  public updateProfile(member: Member): Observable<ServerResponse<MenuResult>> {
    return this.doRequest('post', `/profile/`, member);
  }

  public sendAdsTrackingEvent(url): Observable<ServerResponse<any>> {
    return this.doRequest('get', url, {});
  }

  /* ------------------------- NEW APIS -------------------------------*/

  public getRestaurantDish(DishID): Observable<ServerResponse<{ Dish: RestaurantDish, Restaurant: Restaurant }>> {
    return this.doRequest('get', `/restaurant/dish/${DishID}/`, {});
  }

  public getOrdersList(Page?: number, PerPage?: number): Observable<ServerResponse<OrderList>> {
    return this.doRequest('get', `/orders/`, {Page, PerPage});
  }

  public newOrder(model: NewOrder): Observable<ServerResponse<OrderResponse>> {
    return this.doRequest('post', `/order/`, model);
  }

  public loadOrder(OrderID: number): Observable<ServerResponse<FullOrder>> {
    return this.doRequest('get', `/order/${OrderID}/`, {});
  }

  public postReview(id, Review: number): Observable<ServerResponse<any>> {
    return this.doRequest('post', `/ordergroup/${id}/review/`, {Review});
  }

  public checkCoupon(CouponCode: string): Observable<ServerResponse<Coupon>> {
    return this.doRequest('get', `/coupon/`, {CouponCode});
  }

  public getOrdergroupReview(id: number): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/ordergroup/${id}/review/`, {});
  }

  public searchZipCode(q: string): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/search/zip/?q=${q}`, {});
  }

  public searchCity(q: string): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/search/city/?q=${q}`, {});
  }

  public searchRestaurantCities(q: string): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/search/restaurantcity/?q=${q}`, {});
  }

  public sendVote(restaurandId: number): Observable<ServerResponse<any>> {
    return this.doRequest('post', `/vote/`, {
      restaurantId: restaurandId,
      token: JSON.parse(localStorage.getItem('uid')).value
    });
  }

  public deleteAccount(): Observable<ServerResponse<any>> {
    return this.doRequest('delete', `/profile/`, {});
  }

  public sendFeedback(feedbackForm: Feedback): Observable<ServerResponse<any>> {
      return this.doRequest('post', `/feedback/`, feedbackForm);
  }
}
