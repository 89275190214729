import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RestApiService} from '../../rest-api.service';
import {Restaurant} from '../../api/restaurant';
import * as moment from 'moment';
import {RestaurantMenu} from '../../api/restaurant-menu';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {FirstLoginGuard} from '../../first-login.guard';
import {ModalController, Platform} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {RestaurantDishCategory} from '../../api/restaurant-dish-category';
import {RestaurantDish} from '../../api/restaurant-dish';
import {OrderService} from '../../order.service';
import { environment } from 'src/environments/environment';
import {MmPhoneComponent} from "../../mm-phone/mm-phone.component";
import {MmPhoneVoteComponent} from "../../mm-phone-vote/mm-phone-vote.component";

declare let gaEvent: Function;
declare let jQuery: any;

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.page.html',
  styleUrls: ['./restaurant.page.scss'],
  providers: []
})
export class RestaurantPage implements OnInit, AfterViewInit {

  restId: number = null;
  currentRest: Restaurant = <Restaurant>{Open: {}, Info: {}, Menu: [], Photos: []};
  restaurantServices: String[] = [];

  napok: any[] = [];
  now: any;

  starterMeals: RestaurantMenu[] = [];
  mainMeals: RestaurantMenu[] = [];
  finishMeals: RestaurantMenu[] = [];
  garnishMeals: RestaurantMenu[] = [];
  picklesMeals: RestaurantMenu[] = [];

  loadingMenu: boolean = false;
  loadingRestaurant: boolean = false;

  swiped: boolean = false;
  swipeDirection: number = null;

  dailyMenu: boolean = true;
  openedCategoryID: number = null;

  napSzotar: string[] = [
    'restaurantPage.Sunday',
    'restaurantPage.Monday',
    'restaurantPage.Tuesday',
    'restaurantPage.Wednesday',
    'restaurantPage.Thursday',
    'restaurantPage.Friday',
    'restaurantPage.Saturday',
  ];
  toDayIndex: number = null;

  openHoursBlockOpen: boolean = true;

  galleryImages: any[] = [];

  needVariant: boolean = false;
  isDesktop: boolean = false;

  selectedCategory: RestaurantDishCategory = null;

  imageHost = environment.imageUrl;
  onlyPhone: boolean = false;
  isOrderAble: boolean = false;

  constructor(private arouter: ActivatedRoute,
              private api: RestApiService,
              private socialSharing: SocialSharing,
              private router: Router,
              private cart: OrderService,
              private platform: Platform,
              private firstLoginGuard: FirstLoginGuard,
              private sanitizer: DomSanitizer,
              private modalController: ModalController,
  ) {
    this.now = moment();
    this.toDayIndex = moment().weekday();
    this.isDesktop = this.platform.is('desktop');
    this.arouter.params.subscribe(p => {
      this.restId = p.id;
      if (history.state.date) {
        this.now = moment(history.state.date);
      }
      if (history.state.type) {
        this.dailyMenu = history.state.type === 'menu';
      }
      if (history.state.cat) {
        this.openCategoryPage(history.state.cat);
      }
      this.loadRestaurant();
    });
    this.arouter.queryParams.subscribe(p => {
      if (p.page != null) {
        this.dailyMenu = p.page === 'menu';
      }
      if (!p.cat && this.selectedCategory) {
        this.selectedCategory = null;
      }
    });

  }

  changeToday(to) {
    this.now = to;
    this.napok = [
      moment(this.now).subtract(2, 'days'),
      moment(this.now).subtract(1, 'days'),
      moment(this.now),
      moment(this.now).add(1, 'days'),
      moment(this.now).add(2, 'days')
    ];
    this.loadRestaurant();
  }

  loadRestaurant() {
    this.loadingMenu = true;

    this.api.loadRestaurant(this.restId, this.now.format('YYYY-MM-DD')).subscribe(r => {
      if (r.success) {
        this.needVariant = false;
        this.currentRest = r.data;
        this.starterMeals = [];
        this.mainMeals = [];
        this.finishMeals = [];
        this.garnishMeals = [];
        this.picklesMeals = [];

        for (const i of this.currentRest.Menu) {
          if (i.MenuVariation !== '') {
            this.needVariant = true;
          }
          switch (i.DishType) {
            case 'starter':
              this.starterMeals.push(i);
              break;
            case 'main':
              this.mainMeals.push(i);
              break;
            case 'dessert':
              this.finishMeals.push(i);
              break;
            case 'garnish':
              this.garnishMeals.push(i);
              break;
            case 'pickles':
              this.picklesMeals.push(i);
              break;
          }
        }
        if (this.galleryImages.length !== this.currentRest.Photos.length) {
          for (const i of this.currentRest.Photos) {
            this.galleryImages.push({
              small: `${environment.imageUrl}${i}?w=210&h=140`,
              medium: `${environment.imageUrl}${i}`,
              big: `${environment.imageUrl}${i}`,
              url: `${environment.imageUrl}${i}`,
            });
          }
        }

        // Étterem szolgáltatások megszűrése azokra, amiket meg is tudunk jeleníteni a felületen
        this.restaurantServices = ['wifi','creditcard','parking','discountcard'].filter(x => this.currentRest.Info.RestaurantServices.includes(x) );

        this.loadingMenu = false;
        this.loadingRestaurant = false;

      } else {

      }

      this.onlyPhone = this.currentRest.Info.RestaurantServices.indexOf('phone') !== -1;
      this.isOrderAble = moment().isBetween(
          moment(moment().format('YYYY-MM-DD ') + '00:00:00'),
          moment(moment().format('YYYY-MM-DD ') + this.currentRest.Open.OrderMenuTo)
      );
    }, error => {
      // Ha nem található az étterem, akkor redirect a listára
      if (error.error && error.error.error && error.error.error.code === 404) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit() {
    this.firstLoginGuard.firstTime = false;
    // content title kirajzolására várunk
    this.scrollTitleIntoView();
  }

  ngAfterViewInit() {
    // content title kirajzolására várunk
    this.scrollTitleIntoView();
  }

  private scrollTitleIntoView() {
    setTimeout(() => {
      const element2 = document.querySelector('#content-title');
      if (element2) {
        element2.scrollIntoView();
      }
    }, 300);
  }

  backButton() {
    if (this.selectedCategory) {
      this.selectedCategory = null;
      this.router.navigate([], {queryParams: {}, replaceUrl: true});
    }
    if (this.api.canGoBack) {
      window.history.back();
    } else {
      this.router.navigate(['/']);
    }

  }

  setFavorite() {
    this.loadingRestaurant = true;
    this.api.postFavorite(this.currentRest.Info.RestaurantLink).subscribe(r => {
      if (r.success) {
        this.currentRest.IsFavorite = r.data.Favorite;
        if (this.currentRest.IsFavorite) {
          this.saveEvent('saveFavorite');
        }
      }
      this.loadingRestaurant = false;
    }, e => {
      this.loadingRestaurant = false;
    });
  }

  onSwipe(e) {
    this.swiped = true;
    this.swipeDirection = e.direction;
    if (e.direction === 2) {
      this.changeToday(moment(this.now).add(1, 'days'));
    } else if (e.direction === 4) {
      const yesterday = moment(this.now).subtract(1, 'days');
      if (yesterday.isAfter(moment().format('YYYY-MM-DD'))) {
        this.changeToday(yesterday);
      }
    }
  }

  shareClicked() {
    this.saveEvent('share');
    if (this.isDesktop) {
      this.fb();
    } else {
      this.socialSharing.share(this.currentRest.Info.RestaurantName, this.currentRest.Info.RestaurantName, [], 'https://app.mai.menu/restaurant/' + this.currentRest.Info.RestaurantLink).then(r => {

      });
    }

  }

  fb() {
    const url = 'https://app.mai.menu/restaurant/' + this.currentRest.Info.RestaurantLink;
    const facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + url,
      'facebook-popup',
      'height=350,width=600'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  imgClick(i) {
    window.history.pushState("forward", null, window.location.pathname + window.location.search);
    this.saveEvent('openGallery');
  }

  saveEvent(eventName) {
    gaEvent('restaurant-' + eventName, this.currentRest.Info.RestaurantLink);
  }

  getBackgroundUrl(pic) {
    return this.sanitizer.bypassSecurityTrustStyle('url(' + (pic.small) + ')');
  }

  openCategoryPage(cat: RestaurantDishCategory) {
    if (cat.Items && cat.Items.length) {
      this.openedCategoryID = this.openedCategoryID ? null : cat.CategoryID;
    } else {
      this.selectedCategory = cat;
      this.router.navigate([], {queryParams: {cat: cat.CategoryName}});
    }


  }

  openDish(dish: RestaurantDish) {
    this.router.navigate(['restaurant', this.currentRest.Info.RestaurantLink, dish.DishID]);
  }

  getDishBackgroundUrl(dish: RestaurantDish) {
    if (dish.DishImage) {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + environment.imageUrl + (dish.DishImage) + '?w=72&h=72)');
    }
    return '';
  }

  getImageUrl(dish: RestaurantDish) {
    if (dish.DishImage) {
      return this.sanitizer.bypassSecurityTrustUrl(environment.imageUrl + (dish.DishImage) + '?w=1000');
    }
    return '';
  }

  onImageClick(e: any, dish: RestaurantDish) {
    e.preventDefault();
    e.stopPropagation();
    if (dish.DishImage) {
      jQuery.fancybox.open([
        {
          src: environment.imageUrl + (dish.DishImage) + '?w=1000',
          title: dish.DishName
        }
      ]);
      window.history.pushState("forward", null, window.location.pathname + window.location.search);
    }
  }

  openVoteModal() {
    this.modalController.create({
      mode: 'ios',
      cssClass: 'mm-modal',
      component: MmPhoneVoteComponent,
      componentProps: {
        restaurantId: this.currentRest.Info.RestaurantID,
      },
    }).then(modal => {
      return modal.present();
    });
  }

  openPhoneModal() {
    // Ha nincs telefon szám, akkor nem nyitunk semmit
    if (!this.currentRest.Info.RestaurantPhone) {
      return;
    }

    // Ha telefonon vagyunk(ablak méret alapján), akkor linket nyitunk a telefonos alkalmazásban
    if (this.platform.width() < 768) {
        window.open('tel:' + this.currentRest.Info.RestaurantPhone, '_system');
        return;
    }

    this.modalController.create({
      mode: 'ios',
      cssClass: 'mm-phone-modal',
      component: MmPhoneComponent,
      componentProps: {
        phoneNumber: this.currentRest.Info.RestaurantPhone,
      },
    }).then(modal => {
      return modal.present();
    });
  }
}
