// *A fájl módosítása esetén az src/firebase-messaging-sw.js fájlt is frissíteni kell!

/**
 * PUSH értesítés összeállítása a kapott adatokból
 *
 * Alapértelmezett értékeket állítunk be itt és egységesítjük a több forrásból jövő adatokat
 *
 * @param payload
 */
export function makeNotification(payload) {
    payload.icon = '/assets/icon/icon-72.png';

    // Ha van notification érték, akkor abból megpróbáljuk kitölteni a főbb adatokat, ha nem lenne
    if( payload.notification ) {
        payload.title = payload.title || payload.notification.title;
        payload.body = payload.body || payload.notification.body;
    }

    // Ha van data, akkor onnan is megpróbáljuk kitölteni az alap NotificationOptions értékeket
    if( payload.data ) {
        payload.title = payload.title || payload.data.title;
        payload.body = payload.body || payload.data.body;
        payload.tag = payload.tag || payload.data.tag;
    }
    return payload;
}

/**
 * Link megnyitása action-höz URL
 *
 * @param payload
 */
export function makeNotificationUrl(payload) {
    if( !payload.data || payload.data.action !== 'openLink' ) return undefined;
    else return ''+payload.data.param;
}