import {Component, Input} from '@angular/core';
import {RestApiService} from '../rest-api.service';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../toast.service';

@Component({
  selector: 'app-phone-vote',
  templateUrl: './mm-phone-vote.component.html',
  styleUrls: ['./mm-phone-vote.component.scss'],
})
export class MmPhoneVoteComponent {

   @Input() restaurantId: number = 0;

  constructor(private api: RestApiService,
              private modalController: ModalController,
              private toastService: ToastService) {
  }

  sendVote() {
    this.api.sendVote(this.restaurantId).subscribe(r => {
      if (r.success) {
        this.toastService.presentToast('Sikeres szavazás!');
      } else {
        this.toastService.presentToast(r.error.message);
      }
      this.modalController.dismiss();
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
