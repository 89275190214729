import {Injectable} from '@angular/core';
import {NewOrder} from './api/new-order';
import {Subject} from 'rxjs';
import {RestaurantDish} from './api/restaurant-dish';
import {OrderGroup} from './api/order-group';
import {OrderItem} from './api/order-item';
import {Restaurant} from './api/restaurant';
import {RestApiService} from './rest-api.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {TranslatePipe} from "./translate.pipe";
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  Cart: NewOrder = {Groups: []} as NewOrder;
  CartSubject: Subject<NewOrder> = new Subject<NewOrder>();


  constructor(private api: RestApiService,
              private lang: TranslatePipe,
              private toastService: ToastService,
              private router: Router) {

    this.CartSubject.subscribe(r => {
      this.Cart = r;
      this.saveOrder();
    });
    this.resetOrder();
  }

  public saveOrder() {
    localStorage.setItem('Cart', JSON.stringify(this.Cart));
  }

  public resetOrder() {
    this.Cart = {Groups: []} as NewOrder;

    this.Cart = JSON.parse(localStorage.getItem('Cart') || '{"Groups": []}');
    if (this.Cart.Groups.length) {
      this.CartSubject.next(this.Cart);
    }
  }

  checkDishes(i: OrderItem, j: OrderItem): boolean {
    if (i.DishID !== j.DishID) {
      return false;
    }

    const ie = (i.Extras || []).map((r) => {
      return r.ExtraQty ? r.ExtraID + '-' + r.ExtraQty : undefined;
    }).filter(r => r ? r : undefined).sort();

    const je = (j.Extras || []).map((r) => {
      return r.ExtraQty ? r.ExtraID + '-' + r.ExtraQty : undefined;
    }).filter(r => r ? r : undefined).sort();

    if (JSON.stringify(ie) !== JSON.stringify(je)) {
      return false;
    }


    return true;
  }

  public addDishToCart(restaurant: Restaurant,
                       dish: RestaurantDish,
                       dishType: 'carte' | 'menu',
                       deliveryDate: string,
                       CategoryID: number = null): boolean {

    if (dishType === 'carte' && (dish.DishReqExtras || []).length) {
      const a = dish.Extras.filter(x => x.ExtraQty);

      for (let re of dish.DishReqExtras) {
        const b = a.filter(x => x.ExtraType == re).map(x => {
          return x.ExtraQty;
        }).reduce((a, b) => a + b, 0);
        console.log(re, b, a);
        if (b < 1) {
          this.toastService.presentToast('Kérjük válassz az alábbi opciókból: ' + this.lang.transform('ExtraType.' + re));
          return false;
        } else if (b > 1) {
          this.toastService.presentToast('Az alábbi opciókból csak egy választható: ' + this.lang.transform('ExtraType.' + re));
          return false;
        }
      }

    }

    const success = this._addDishToCart(restaurant, dish, dishType, deliveryDate, CategoryID);

    if (success) {
      if (dish.DishQty > 0) {
        this.toastService.presentToast('Sikeresen kosárba tetted');
      } else if (dish.DishQty < 0) {
        this.toastService.presentToast('Sikeresen kivetted a kosárból');
      } else {
        this.toastService.presentToast('Sikeresen módosítottad az extrákat');
      }
    }
    return success;
  }

  private _addDishToCart(restaurant: Restaurant,
                         dish: RestaurantDish,
                         dishType: 'carte' | 'menu',
                         deliveryDate: string,
                         CategoryID: number = null): boolean {
    /*if (!this.api.myMember.MemberToken) {
      this.api.isVisitor = false;
      setTimeout(() => {
        this.router.navigate(['/login-required']);
      }, 0);
      return false;
    }*/

    let isOrderAble = false;

    if (dishType === 'menu') {
      if (moment(deliveryDate).isSame(new Date(), 'day')) {
        isOrderAble = moment().isBetween(
          // moment(moment().format('YYYY-MM-DD ') + restaurant.Open.MenuOpen),
          moment(moment().format('YYYY-MM-DD ') + '00:00:00'),
          moment(moment().format('YYYY-MM-DD ') + restaurant.Open.OrderMenuTo)
        );
      } else {
        isOrderAble = true;
      }

    } else {
      isOrderAble = moment().isBetween(
        // moment(moment().format('YYYY-MM-DD ') + restaurant.Open.RestaurantOpen),
        moment(moment().format('YYYY-MM-DD ') + '00:00:00'),
        moment(moment().format('YYYY-MM-DD ') + restaurant.Open.OrderCarteTo)
      );
    }

    if ((!isOrderAble) && (dish.DishQty > 0)) {
      this.toastService.presentToast('Az étterem már nem fogad megrendeléseket');
      return false;
    }

    for (const g of this.Cart.Groups) {
      if ((g.RestaurantID === restaurant.Info.RestaurantID)
        && (g.DeliveryDate === deliveryDate)
        && (g.DishType === dishType)) {
        for (const i of g.Items) {
          if (this.checkDishes(i, dish as OrderItem)) {
            i.DishQty += dish.DishQty;
            if (i.DishQty < 1) {
              const dishIndex = g.Items.indexOf(i);
              g.Items.splice(dishIndex, 1);
              if (g.Items.length < 1) {
                const groupIndex = this.Cart.Groups.indexOf(g);
                this.Cart.Groups.splice(groupIndex, 1);
              }
              this.CartSubject.next(this.Cart);
              return true;
            }

            this.CartSubject.next(this.Cart);
            return true;
          }
        }

        const item: OrderItem = {
          DishID: dish.DishID,
          DishQty: dish.DishQty,
          DishName: dish.DishName,
          DishPrice: (dishType === 'menu') ? dish.DishPrice : dish['DishCartePrice' + dish.DishVariantIndex + 'Current'],
          DishType: dish.DishType,
          DishPriceNumber: dish.DishVariantIndex,
          DishPriceLabel: dish['DishCartePrice' + dish.DishVariantIndex + 'Label'],
          CategoryID: CategoryID,
          Extras: [],
          DishReqExtras: dish.DishReqExtras,
        };
        if (dish.Extras && dish.Extras.length) {
          for (const e of dish.Extras) {
            if (e.ExtraQty) {
              item.Extras.push({
                ExtraID: e.ExtraID,
                ExtraQty: e.ExtraQty,
                ExtraPrice: e['ExtraPrice' + dish.DishVariantIndex + 'Current'],
                ExtraName: e.ExtraName,
                ExtraType: e.ExtraType,
              });
            }
          }
        }
        g.Items.push(item);
        this.CartSubject.next(this.Cart);
        return true;
      }
    }
    if (dish.DishQty < 1) {
      return false;
    }
    const group: OrderGroup = {
      RestaurantID: restaurant.Info.RestaurantID,
      Restaurant: restaurant,
      DeliveryDate: deliveryDate,
      DishType: dishType,
      Items: [{
        DishID: dish.DishID,
        DishQty: dish.DishQty,
        DishName: dish.DishName,
        DishPrice: (dishType === 'menu') ? dish.DishPrice : dish['DishCartePrice' + dish.DishVariantIndex + 'Current'],
        DishPriceNumber: dish.DishVariantIndex,
        DishPriceLabel: dish['DishCartePrice' + dish.DishVariantIndex + 'Label'],
        DishType: dish.DishType,
        CategoryID: CategoryID,
        Extras: [],
        DishReqExtras: dish.DishReqExtras,
      }],
    } as OrderGroup;
    if (dish.Extras && dish.Extras.length) {
      for (const e of dish.Extras) {
        if (e.ExtraQty) {
          group.Items[0].Extras.push({
            ExtraID: e.ExtraID,
            ExtraQty: e.ExtraQty,
            ExtraPrice: e['ExtraPrice' + dish.DishVariantIndex + 'Current'],
            ExtraName: e.ExtraName,
            ExtraType: e.ExtraType,
          });
        }
      }

    }
    this.Cart.Groups.push(group);
    this.CartSubject.next(this.Cart);
    return true;
  }

}
